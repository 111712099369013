@use 'sass:color';
@use 'sass:math';

$default-contrast-threshold: 128 !default;
$default-dark-contrast-color: #0a0a0a !default;
$default-light-contrast-color: #ffffff !default;

@function rgb-to-yiq($hex) {
  @return math.div(color.red($hex) * 299 + color.green($hex) * 587 + color.blue($hex) * 114, 1000);
}

@function get-color-shade($color) {
  @return color.mix(#000, $color, 12%);
}

@function get-color-tint($color) {
  @return color.mix(#fff, $color, 10%);
}

@function hex-to-rgb-list($hex) {
  @return #{color.red($hex)}, #{color.green($hex)}, #{color.blue($hex)};
}

@function get-contrast-color($hex-color, $light-color, $dark-color) {
  @if rgb-to-yiq($hex-color) >= $default-contrast-threshold {
    @return $dark-color;
  } @else {
    @return $light-color;
  }
}

@mixin define-color-set($name, $hex-color, $contrast-threshold: $default-contrast-threshold, $contrast-color: null) {
  --#{$name}: #{$hex-color};
  --#{$name}-rgb: #{hex-to-rgb-list($hex-color)};

  @if $contrast-color {
    --#{$name}-contrast: #{$contrast-color};
    --#{$name}-contrast-rgb: #{hex-to-rgb-list($contrast-color)};
  } @else {
    @if rgb-to-yiq($hex-color) >= $contrast-threshold {
      --#{$name}-contrast: #{$default-dark-contrast-color};
      --#{$name}-contrast-rgb: #{hex-to-rgb-list($default-dark-contrast-color)};
    } @else {
      --#{$name}-contrast: #{$default-light-contrast-color};
      --#{$name}-contrast-rgb: #{hex-to-rgb-list($default-light-contrast-color)};
    }
  }
  --#{$name}-shade: #{get-color-shade($hex-color)};
  --#{$name}-tint: #{get-color-tint($hex-color)};
}

@mixin define-color-variable($name, $hex) {
  --#{$name}: #{$hex};
  --#{$name}-rgb: #{split-rgb($hex)};
}

@mixin extend-color-variable($to, $from) {
  --#{$to}: var(--#{$from});
  --#{$to}-rgb: var(--#{$from}-rgb);
}
