/* You can add global styles to this file, and also import other style files */

@use 'theme';
@import 'https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;600;700&family=Noto+Sans+JP:wght@400;500;600;700&family=Cabin:wght@400;500&display=swap';

:root {
  --tpl-font-family: 'Noto Sans JP', '游ゴシック体', '游ゴシック', 'YuGothic', 'Yu Gothic Medium', 'Yu Gothic',
    'Hiragino Sans', 'ヒラギノ角ゴシック', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro', 'Meiryo', sans-serif;
  --tpl-number-font-family: 'Barlow Semi Condensed', sans-serif;
  --tpl-latin-font-family: 'Cabin', sans-serif;
}
