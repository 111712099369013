@use '@twogate-npm/toolbox-utils/styles/define-color' as colUtil;
@use './app';
@use './util' as util;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

$primary: #dc256a !default;
$primary-contrast: null !default;
$accent: #2d3091 !default;
$accent-contrast: null !default;

$store: #25cf9d !default;
$attention: #f23325 !default;
$disabled: #cccccc !default;
$success: #2dd36f !default;

$background: #ffffff !default;
$background-accent: #eeeeee !default;
$background-disabled: #e3e3e3 !default;

// light-* が Light テーマ用
// dark-* が Dark テーマ用
$light-text: #0a0a0a !default;
$light-text-tint: #727272 !default;

$dark-text: #ffffff !default;
$dark-text-tint: #727272 !default;
$border: #eeeeee !default;

// application
$header-background: #ffffff !default;
$footer-background: #0a0a0a !default;
$form-background: #f8f8f8 !default;
$item-background: #ffffff !default;
$item-background-accent: #f8f8f8 !default;
$modal-background: #ffffff !default;
$modal-header-background: null !default;
$site-menu-button-background: null !default;
$site-menu-button-color: null !default;
$site-menu-header-background: null !default;
$site-footer-copyright-color: null !default;

$main-max-width: 760px !default;

$is-dark: false !default;
$radius: 4px !default;

:root {
  @include colUtil.define-color-set(
    'tpl-color-primary',
    $primary,
    colUtil.$default-contrast-threshold,
    $primary-contrast
  );
  @include colUtil.define-color-set('tpl-color-accent', $accent, colUtil.$default-contrast-threshold, $accent-contrast);
  @include colUtil.define-color-set('tpl-color-store', $store);
  @include colUtil.define-color-set('tpl-color-attention', $attention);
  @include colUtil.define-color-set('tpl-color-disabled', $disabled);
  @include colUtil.define-color-set('tpl-color-success', $success);
  @include colUtil.define-color-set('tpl-color-background', $background);
  @include colUtil.define-color-set('tpl-color-background-accent', $background-accent);
  @include colUtil.define-color-set('tpl-color-border', $border);
  @include colUtil.define-color-set('tpl-color-background-disabled', $background-disabled);

  @if $is-dark {
    @include colUtil.define-color-set('tpl-color-text', $dark-text);
    @include colUtil.define-color-set('tpl-color-text-light', $dark-text-tint);
  } @else {
    @include colUtil.define-color-set('tpl-color-text', $light-text);
    @include colUtil.define-color-set('tpl-color-text-light', $light-text-tint);
  }

  @include colUtil.define-color-set('tpl-color-header-background', $header-background);
  @include colUtil.define-color-set('tpl-color-footer-background', $footer-background);
  @include colUtil.define-color-set('tpl-color-form-background', $form-background);
  @include colUtil.define-color-set('tpl-color-item-background', $item-background);
  @include colUtil.define-color-set('tpl-color-item-background-accent', $item-background-accent);
  @include colUtil.define-color-set('tpl-color-modal-background', $modal-background);

  @if $modal-header-background {
    @include colUtil.define-color-set('tpl-color-modal-header-background', $modal-header-background);
  }

  @if $site-menu-button-background {
    @include colUtil.define-color-set('tpl-color-site-menu-button-background', $site-menu-button-background);
  }

  @if $site-menu-header-background {
    @include colUtil.define-color-set('tpl-color-site-menu-header-background', $site-menu-header-background);
  }

  @if $site-menu-button-color {
    @include colUtil.define-color-set('tpl-color-site-menu-button-color', $site-menu-button-color);
  }

  @if $site-footer-copyright-color {
    @include colUtil.define-color-set('tpl-color-site-footer-copyright-color', $site-footer-copyright-color);
  }

  --tpl-radius: #{$radius};
  --tpl-main-max-width: #{$main-max-width};
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary-palette: util.get-mat-palette($primary, $light-text, $dark-text);
$accent-palette: util.get-mat-palette($accent, $light-text, $dark-text);
$warn-palette: util.get-mat-palette($attention, $light-text, $dark-text);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($primary-palette),
      accent: mat.define-palette($accent-palette),
      warn: mat.define-palette($warn-palette),
    ),
    typography: mat.define-typography-config(),
  )
);

// @include mat.all-component-themes($theme); はすべてのコンポーネントのテーマを適用する
// 基本的に使うもののみを適用する
// https://github.com/angular/components/blob/main/src/material/core/theming/_all-theme.scss
@include mat.core-theme($theme);
@include mat.button-theme($theme);
@include mat.datepicker-theme($theme);
@include mat.select-theme($theme);
@include mat.option-theme($theme);
