@import '@angular/cdk/overlay-prebuilt.css';

html {
  background-color: var(--tpl-color-background);
  color: var(--tpl-color-text);
  font-size: 10px;
  height: calc(100% + env(safe-area-inset-top) + env(safe-area-inset-bottom));
}

body {
  font-family: var(--tpl-font-family);
  font-size: 1.6rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  margin: 0;
  position: relative;
  text-rendering: auto;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  word-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
textarea {
  background-color: transparent;
  margin: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

tbx-wysiwyg-body {
  --app-color-primary: var(--tpl-color-primary);
}

.cdk-dialog-container {
  outline: none;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 50%);
}

.tpl-toast-container {
  height: 100%;
  pointer-events: none !important;
  width: 100%;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.grecaptcha-badge {
  visibility: hidden;
}
